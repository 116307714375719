.header {
    width: 100%;
    height: 64px;
    flex-shrink: 0;
    border-bottom: 1px solid #E8EAEE;
    background: var(--Neutral-neutral00, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
}
.content-container {
    height: calc(100vh - 64px)
}
.button {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-text {
    font-weight: 500;
    font-size: 16px !important;
}

.login-text {
    font-size: 24px !important;
    font-weight: 700 !important;
    margin-top: 32px;
}
.qr-code {
    margin-top: -7px;
}

.unauthorised-text-1-l {
    color:  #161E2D;
    text-align: center;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 36px !important; /* 112.5% */
    width: 608px;
    height: 72px;
    align-self: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.unauthorised-text-2-l {
    color:  #161E2D;
    text-align: center;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px !important; /* 133.333% */
    align-self: center;
    width: 503px;
    height: 64px;
}

.unauthorised-text-3-l {
    color:  #161E2D;
    text-align: center;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px !important; /* 160% */
    align-self: center;
}

.unauthorised-text-4-l {
    color: #161E2D;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 32px !important;
    align-self: center;
}